import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {ArticleCardItemComponent} from './article-card-item/article-card-item.component';
import {ArticleCardItemHorizontalComponent} from './article-card-item-horizontal/article-card-item-horizontal.component';
import {BasketItemComponent} from './basket/basket-item/basket-item.component';
import {FooterComponent} from './desktop/footer/footer.component';
import {HeaderBannerComponent} from './header-banner/header-banner.component';
import {RouterModule} from '@angular/router';
import {IconButtonComponent} from './icon-button/icon-button.component';
import {UserSalutationComponent} from './user-salutation/user-salutation.component';
import {CardElementComponent} from './card-element/card-element.component';

@NgModule({
    declarations: [
        ArticleCardItemComponent,
        BasketItemComponent,
        FooterComponent,
        HeaderBannerComponent,
        IconButtonComponent,
        UserSalutationComponent,
        CardElementComponent,
        ArticleCardItemHorizontalComponent
    ],
    imports: [
        ReactiveFormsModule,
        IonicModule,
        CommonModule,
        FormsModule,
        RouterModule
    ],
    exports: [
        ArticleCardItemComponent,
        BasketItemComponent,
        FooterComponent,
        HeaderBannerComponent,
        IconButtonComponent,
        UserSalutationComponent,
        CardElementComponent,
        ArticleCardItemHorizontalComponent
    ]
})

export class ComponentsModule {

}
