<ng-container *ngIf="!horizontal">
    <div [class.desktop]="environment.desktop">
        <ion-card class="md hydrated" mode="ios">
            <div class="article-image-wrapper"
                 *ngIf="articleImage || this.clientService.client?.articlePlaceholder"
                 [style.background-image]="articleImage ? 'url(' + articleImage.path + ')' : 'url(' + clientService.client?.articlePlaceholder.path + ')'"></div>
            <div style="margin:10px 10px 5px">
                <ion-grid class="ion-no-padding">
                    <ion-row>
                        <ion-col>
                            <ion-label>
                                <div class="card-title-menu-bold">
                                    <ng-container *ngIf="title">
                                        {{(title.length > dataService.maxCharacters) ? (title | slice:0:dataService.maxCharacters) + '...' : (title)}}
                                    </ng-container>
                                    <ng-container *ngIf="!title">
                                        {{(article.title.length > dataService.maxCharacters) ? (article.title | slice:0:dataService.maxCharacters) + '...' : (article.title)}}
                                    </ng-container>
                                </div>
                            </ion-label>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label>
                                <div class="card-title-menu"
                                     *ngIf="!overridePriceSection">{{ article.price | currency: 'EUR' }}
                                    pro {{ article.unit.label }}</div>
                                <div class="card-title-menu" *ngIf="overridePriceSection"
                                      [innerHTML]="overridePriceSection"></div>
                            </ion-label>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>
        </ion-card>
    </div>
</ng-container>
