import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {MenusPageRoutingModule} from './menus-routing.module';

import {MenusPage} from './menus.page';
import {ComponentsModule} from '../../components/components.module';
import {MenuCardItemComponent} from '../../components/menu-card-item/menu-card-item.component';
import {SwiperModule} from 'swiper/angular';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        MenusPageRoutingModule,
        ComponentsModule,
        SwiperModule
    ],
    exports: [
        MenuCardItemComponent
    ],
    declarations: [MenusPage, MenuCardItemComponent]
})
export class MenusPageModule {}
