import {Serializable} from './serializable.abstract';
import {CheckoutBasketItem} from './checkout-basket-item.model';

export class CheckoutResponseDTO extends Serializable {
    pickupMin: Date;
    pickupMax: Date;
    availableDays: number[];
    disabledDays: number[];
    leadTimeMax: number;
    paymentPossible: boolean;
    checkoutBasket: CheckoutBasketItem[] = [];

    deserialize(input: any): Serializable {
        super.deserialize(input);
        this.deserializeComplexArray('checkoutBasket', input, CheckoutBasketItem);
        return this;
    }
}

export class CheckoutResponse extends CheckoutResponseDTO {
}
