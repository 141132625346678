<ion-row *ngIf="checkoutBasketItem">
    <ion-col size="6">
        <div *ngIf="checkoutBasketItem.type !== 2"><b>{{ checkoutBasketItem.title }}</b></div>
        <div *ngIf="checkoutBasketItem.type === 2"><b>{{ checkoutBasketItem.title }}</b></div>
        <div *ngIf="checkoutBasketItem.additionalArticles?.length" style="font-size: 13px;">
            <ng-container *ngFor="let menuArticle of checkoutBasketItem.additionalArticles">
                <div style="font-size: 12px">+ {{menuArticle.title}}</div>
            </ng-container>
        </div>
        <div style="margin-top: 5px">
            <span style="color: var(--ion-color-primary)">{{ checkoutBasketItem.price | currency: 'EUR' }}</span>
            <span style="font-size: small"> pro {{ checkoutBasketItem.unit.label }}</span>
        </div>
        <div style="margin-top: 5px" *ngIf="checkoutBasketItem.comment">
            <div style="font-size: x-small; font-style: italic; line-height: 1">{{checkoutBasketItem.comment}}</div>
        </div>
    </ion-col>
    <ion-col size="6">
        <ion-row class="ion-align-items-center">
            <ion-col class="ion-text-center" size="4">
                <ng-container *ngIf="!checkoutBasketItem.isDecreasable">
                    <ion-button size="small" expand="full" class="ion-no-margin"
                                (click)="removeArticleFromBasket(checkoutBasketItem)">
                        <ion-icon name="trash-outline"></ion-icon>
                    </ion-button>
                </ng-container>
                <ng-container *ngIf="checkoutBasketItem.isDecreasable">
                    <ion-button size="small" expand="full" class="ion-no-margin"
                                (click)="decreaseAmount(checkoutBasketItem)">
                        <ion-icon name="remove-outline"></ion-icon>
                    </ion-button>
                </ng-container>
            </ion-col>
            <ion-col class="ion-text-center" size="4">
                <span style="font-size: x-small">{{ checkoutBasketItem.amount }}  {{ checkoutBasketItem.sellUnit.label }}</span>
            </ion-col>
            <ion-col class="ion-text-center" size="4">
                <ion-button size="small" expand="full" class="ion-no-margin"
                            [disabled]="!checkoutBasketItem.isIncreasable"
                            (click)="increaseAmount(checkoutBasketItem)">
                    <ion-icon name="add-outline"></ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-button size="small" color="secondary" expand="full" class="ion-no-margin"
                            (click)="addNote(checkoutBasketItem)">
                    <ion-icon name="create-outline"></ion-icon>
                    Anmerkung
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="checkoutBasketItem?.minItems > 1" class="ion-no-padding">
            <ion-col class="ion-text-center ion-no-padding">
                <span style="font-size: 8px">Mindestbestellmenge von {{checkoutBasketItem?.minItems}} {{ checkoutBasketItem.sellUnit.label }}</span>
            </ion-col>
        </ion-row>
    </ion-col>
    <ion-col size="12">
        <hr/>
    </ion-col>
</ion-row>
