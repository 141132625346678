import {ChangeDetectorRef, Component, HostBinding} from '@angular/core';

import {AlertController, ModalController, NavController, Platform} from '@ionic/angular';
import {Router} from '@angular/router';
import {BasketService} from '../../services/basket.service';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {DomSanitizer} from '@angular/platform-browser';
import {appSettings} from '../../../app-settings';
import {NotificationService} from '../../services/notification/notification.service';
import {DataService} from '../../services/data.service';
import {ClientService} from '../../services/client.service';
import {VersionService} from '../../services/util/version.service';
import {ModalUpdateComponent} from '../../modals/modal-update/modal-update.component';
import {ClientAvailability} from '../../enums/client-availability.enum';
import {ModalAvailabilityComponent} from '../../modals/modal-availability/modal-availability.component';
import {environment} from '../../../environments/environment';
import {DeviceService} from '../../services/util/device.service';
import {first} from 'rxjs/operators';
import {DateService} from '../../services/util/date.service';
import {BranchService} from '../../services/branch.service';
import {SplashScreen} from '@capacitor/splash-screen';
import {StatusBar, Style} from '@capacitor/status-bar';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    navigate: any;

    public environment = environment;

    @HostBinding('attr.style')
    public get valueAsStyle(): any {
        return this.sanitizer.bypassSecurityTrustStyle(`--window-width: ${this.dataService.width}px; --productImageRatio: ${appSettings.productImageRatio}; --productCols: ${this.dataService.productCols}`);
    }

    constructor(
        private platform: Platform,
        public basketService: BasketService,
        private router: Router,
        private alertController: AlertController,
        public notificationService: NotificationService,
        private deviceService: DeviceService,
        private appVersion: AppVersion,
        private versionService: VersionService,
        private sanitizer: DomSanitizer,
        private dataService: DataService,
        private clientService: ClientService,
        private modalController: ModalController,
        private dateService: DateService,
        private cdRef: ChangeDetectorRef,
        private branchService: BranchService,
        private navController: NavController
    ) {
        this.tapMenu();
        this.initializeApp();
        this.backButtonEvent();
    }

    private async initializeApp() {
        await this.platform.ready();

        this.dateService.initTime();

        await this.deviceService.initializeUniqueDeviceId();

        if (this.platform.is('cordova') && this.platform.is('mobile') && !this.platform.is('mobileweb')) {
            await StatusBar.setStyle({style: Style.Default});

            try {
                await this.checkAppVersion();

                await this.getClientAndCheckAvailability();

                await this.initPushNotifications();
            } catch (e) {
                console.log(e);
            }
            await SplashScreen.hide();
        } else {
            try {
                await this.getClientAndCheckAvailability();
            } catch (e) {

            }
        }
        try {
            await this.branchService.getBranches();
        } catch (e) {

        }
    }

    private async checkAppVersion() {
        const versionNumber = await this.appVersion.getVersionNumber();

        if (versionNumber) {
            try {
                const versionCheckResponse = await this.versionService.check(versionNumber);

                if (versionCheckResponse.updateAvailable) {
                    const modal = await this.modalController.create({
                        component: ModalUpdateComponent,
                        componentProps: {
                            breaking: versionCheckResponse.breaking
                        }
                    });

                    await modal.present();
                }
            } catch (e) {

            }

        }
    }

    private async getClientAndCheckAvailability() {
        try {
            const client = await this.clientService.getClient();

            if (client.availability) {
                switch (client.availability.status) {
                    case ClientAvailability.DISABLED:
                        const modal = await this.modalController.create({
                            component: ModalAvailabilityComponent
                        });
                        await modal.present();
                        break;
                    case ClientAvailability.RESTRICTED:
                        const alert = await this.alertController.create({
                            header: 'Bestellungen deaktiviert',
                            message: 'Aktuell können keine Bestellungen entgegengenommen werden.',
                            buttons: [
                                {
                                    text: 'Verstanden',
                                    role: 'cancel'
                                }
                            ]
                        });
                        await alert.present();
                        break;
                }
            }
        } catch (e) {
            const modal = await this.modalController.create({
                component: ModalAvailabilityComponent
            });
            await modal.present();
        }

    }

    private async initPushNotifications() {
        this.notificationService.addPushNotificationEventListeners();
        await this.notificationService.setupPushNotifications();
        this.notificationService.onTokenReceived.pipe(first()).subscribe(async (token: string) => {
            if (token) {
                try {
                    await this.notificationService.registerNotifications(token);
                    await this.notificationService.getNotifications(token);
                    this.notificationService.onNotificationReceived.subscribe(async () => {
                        await this.notificationService.getNotifications(token);
                        this.cdRef.detectChanges();
                    });
                } catch (e) {
                }
            }
        });
    }

    private tapMenu() {
        this.navigate =
            [
                {
                    title: 'Dashboard',
                    url: 'dashboard',
                    icon: 'home-outline'
                },
                {
                    title: 'Produkte',
                    url: 'products',
                    icon: 'restaurant-outline'
                },
                {
                    title: 'Warenkorb',
                    url: 'basket',
                    icon: 'basket-outline'
                },
                {
                    title: 'Benachrichtigungen',
                    url: 'notifications',
                    icon: 'notifications-outline'
                },
                {
                    title: 'Mehr',
                    url: 'more',
                    icon: 'ellipsis-horizontal-outline'
                }
            ];
    }

    /**
     * This method actives hardware back button
     */
    private backButtonEvent() {
        this.platform.backButton.subscribeWithPriority(10, async () => {
            if (this.router.url.includes('/products/')) {
                this.navController.back();
                return;
            }
            switch (this.router.url) {
                case '/dashboard':
                    navigator['app'].exitApp();
                    break;
                case '/products':
                case '/basket':
                case '/notifications':
                case '/menus':
                case '/more':
                    await this.router.navigate(['/dashboard']);
                    break;
                case '/more/profile':
                case '/more/orders':
                case '/more/about':
                    await this.router.navigate(['/more']);
                    break;
                case '/order':
                    await this.router.navigate(['/basket']);
                    break;
                default:
            }
        });
    }
}


