import {Serializable} from './serializable.abstract';
import {BasketItem} from './basket-item.model';

export class BasketDTO extends Serializable {

}

export class Basket extends BasketDTO {
    items: BasketItem[] = [];

    get articles() {
        return this.items.filter(item => !item.additionalArticles.length);
    }

    get menus() {
        return this.items.filter(item => item.type === 1 || item.type === 2);
    }

    public deserialize(input: any): Serializable {
        super.deserialize(input);
        if (input) {
            this.deserializeComplexArray('items', input, BasketItem);
        }
        return this;
    }

    serializeCheckout(): any {
        const ret = [];

        this.items.forEach(basketItem => {
            const checkoutItem = {
                id: basketItem.id,
                type: basketItem.type,
                amount: basketItem.amount,
                comment: basketItem.comment
            };

            if (basketItem.lunchDay) {
                Object.assign(checkoutItem, {date: basketItem.lunchDay.date});
            }

            if (basketItem.additionalArticles.length) {
                Object.assign(checkoutItem, {additionalArticles: basketItem.additionalArticles.map(additionalArticle => additionalArticle.serialize())});
            }

            ret.push(checkoutItem);
        });

        return ret;
    }
}
