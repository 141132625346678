import {Serializable} from './serializable.abstract';
import {DayConfig} from 'ion2-calendar';

export class BranchDTO extends Serializable {
    title = '';
    street = '';
    zip = '';
    city = '';
    nonOpeningDays: Date[];
    pickupTimes: [{ day: number, hours: number[], minutes: number[], summary: string[] }];

    public deserialize(input: any): Serializable {
        return super.deserialize(input);
    }
}

export class Branch extends BranchDTO {
    get nonOpeningDaysConfig() {
        const _daysConfig: DayConfig[] = [];

        if (this.nonOpeningDays) {
            this.nonOpeningDays.forEach(nonOpeningDay => {
                _daysConfig.push({
                    date: nonOpeningDay,
                    disable: true,
                    cssClass: 'non-opening-day'
                });
            });
        }

        return _daysConfig;
    }
}
