import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateService {
    public time = new Date();
    public hour: number;
    public timer;

    public timeFormat = 'H:mm';
    public dateLocale = 'de';

    public weekDayTitles = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

    constructor() {
    }

    public initTime() {
        this.hour = this.time.getHours();
        this.timer = setInterval(() => {
            this.time = new Date();
            this.hour = this.time.getHours();
        }, 1000);
    }

    public getNumberOfWeek(): number {
        const today = new Date();
        const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
        const pastDaysOfYear = Math.floor((today.valueOf() - firstDayOfYear.valueOf()) / 86400000);
        return Math.floor((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }

    public getWeekDayTitle(index) {
        return this.weekDayTitles[index];
    }
}
