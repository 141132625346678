import {Component} from '@angular/core';
import {MenuService} from '../../services/menu.service';
import {LunchWeek} from '../../models/lunch-week.model';
import {ModalController, ToastController, ViewWillEnter} from '@ionic/angular';
import {ClientService} from '../../services/client.service';
import {LunchArticle} from '../../models/lunch-article.model';
import {
    ModalLunchMenuConfigurationComponent
} from '../../modals/modal-lunch-menu-configuration/modal-lunch-menu-configuration.component';
import {LunchDay} from '../../models/lunch-day.model';
import {environment} from '../../../environments/environment';
import {BasketService} from '../../services/basket.service';
import {LoadingService} from '../../services/util/loading.service';
import {DateService} from '../../services/util/date.service';
import SwiperCore, {Swiper} from 'swiper';
import {DataService} from '../../services/data.service';
import {DatePipe} from '@angular/common';

SwiperCore.use([]);

@Component({
    selector: 'app-menus',
    templateUrl: './menus.page.html',
    styleUrls: ['./menus.page.scss'],
})
export class MenusPage implements ViewWillEnter {
    public lunchDays: LunchDay[];

    get currentWeek(): LunchWeek | null {
        if (!this.lunchWeeks.length) {
            return null;
        }
        const currentWeek = this.lunchWeeks.find(week => week.currentWeek === this.dateService.getNumberOfWeek());

        if (!currentWeek) {
            return this.lunchWeeks[0];
        }

        return currentWeek;
    }

    get lunchWeekRange(): string {
        if (!this.lunchWeeks.length) {
            return '';
        }

        if (this.lunchWeeks.length < 2) {
            return this.lunchWeeks[0].currentWeek.toString(10);
        }

        return this.lunchWeeks[0].currentWeek + ' - ' + this.lunchWeeks[this.lunchWeeks.length - 1].currentWeek;
    }

    public environment = environment;

    constructor(
        private menuService: MenuService,
        public clientService: ClientService,
        private basketService: BasketService,
        private modalController: ModalController,
        private toastController: ToastController,
        private loadingService: LoadingService,
        public dateService: DateService,
        private dataService: DataService,
        private datePipe: DatePipe
    ) {
    }

    public articleSlider: Swiper;

    public loading = true;

    public lunchWeeks: LunchWeek[] = [];
    public activeSlideIndex = 0;

    private toast = null;

    public currentDayOfWeek = 0;

    public slidesPerViewDesktop = 7.5;

    async ionViewWillEnter() {
        this.loading = await this.loadingService.createLoading();
        await this.calculateSlidesPerViewDesktop();
        try {
            this.lunchWeeks = await this.menuService.getCurrentWeeks();
            this.lunchDays = this.getLunchDays();
            await this.articleSlider.update();
            const activeDay = this.getActiveDayIndex();
            this.activeSlideIndex = activeDay;
            await this.articleSlider.slideTo(activeDay === 0 ? activeDay : activeDay - 1);
        } catch (e) {
            console.log(e);
            this.lunchWeeks = [];
        }
        this.loading = await this.loadingService.dismissLoading();

        window.addEventListener('resize', () => {
            this.calculateSlidesPerViewDesktop();
        });
    }

    getLunchDays(): LunchDay[] {
        let lunchDays = [];
        if (!this.lunchWeeks.length) {
            return lunchDays;
        }

        this.lunchWeeks.forEach(lunchWeek => {
            lunchDays = lunchDays.concat(lunchWeek.lunchDays);
        });

        return lunchDays;
    }

    private async calculateSlidesPerViewDesktop() {
        if (!environment.desktop) {
            return;
        }
        this.slidesPerViewDesktop = 7.5;
        if (this.dataService.width < 1350) {
            this.slidesPerViewDesktop = 5.5;
        }
        if (this.dataService.width < 768) {
            this.slidesPerViewDesktop = 2.5;
        }
        this.articleSlider.update();
        await this.articleSlider.slideTo(this.activeSlideIndex === 0 ? this.activeSlideIndex : this.activeSlideIndex - 1);
    }

    public setSwiperInstance(ev) {
        this.articleSlider = ev;
    }

    public async openMenu(index: number) {
        this.activeSlideIndex = index;
    }

    private getActiveDayIndex() {
        const now = new Date();

        // no lunchDays exist
        if (!this.lunchWeeks.length) {
            return -1;
        }

        let index = this.lunchDays.findIndex(day => day.date === this.datePipe.transform(now, 'yyyy-MM-dd'));

        // lunchDay exists for current day
        if (index >= 0) {
            return index;
        }

        // NO lunchDay exists for current day
        // create lunchDay without any articles for current day
        const lunchDay = new LunchDay();
        lunchDay.date = this.datePipe.transform(now, 'yyyy-MM-dd');
        lunchDay.expired = false;
        lunchDay.title = this.dateService.getWeekDayTitle(now.getDay());

        // insert lunchDay into existing array
        index = this.sortedIndex(this.lunchDays.map(({date}) => date), lunchDay.date);
        this.lunchDays.splice(index, 0, lunchDay);

        return index;
    }

    sortedIndex(array, value) {
        let low = 0,
            high = array.length;

        while (low < high) {
            const mid = (low + high) >>> 1;
            if (array[mid] < value) {
                low = mid + 1;
            } else {
                high = mid;
            }
        }
        return low;
    }

    public async openMenuModal(lunchArticle: LunchArticle, lunchDay: LunchDay) {
        if (lunchDay.expired) {

            if (!this.toast) {
                this.toast = await this.toastController.create({
                    message: this.clientService.client.textElements.infos?.menuNotAvailable,
                    duration: 2000,
                    position: 'bottom',
                    color: 'secondary'
                });
                await this.toast.present();

                this.toast.onDidDismiss().then(() => {
                    this.toast = null;
                });
            }
            return;
        }
        const foundMenuIndex = this.basketService.basket.menus.findIndex(basketItem => {
            if (!basketItem.lunchDay) {
                return false;
            }
            return basketItem.lunchDay.date !== lunchDay.date;
        });

        if (foundMenuIndex >= 0) {
            const toast = await this.toastController.create({
                message: 'Mittagsmenüs unterschiedlicher Tage müssen separat bestellt werden',
                duration: 0,
                buttons: [
                    {
                        text: 'Alles klar'
                    }
                ],
                position: 'bottom',
                color: 'secondary'
            });
            await toast.present();
            return;
        }

        const modal = await this.modalController.create({
            component: ModalLunchMenuConfigurationComponent,
            componentProps: {
                lunchArticle,
                lunchDay,
                amount: 1
            }
        });

        await modal.present();
    }
}
