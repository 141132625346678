import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Article} from '../models/article.model';
import {DataService} from './data.service';
import {Day} from '../enums/day.enum';
import {environment} from '../../environments/environment';
import {Allergen, AllergenDTO} from '../models/allergen';


@Injectable({
    providedIn: 'root'
})
export class ArticleService {

    public allArticles: Article[] = [];

    constructor(
        private dataService: DataService
    ) {
    }

    public async getArticles(category = 0, search = '', allergens = ''): Promise<Article[]> {
        const params = {};
        if (category) {
            Object.assign(params, {category});
        }
        if (search) {
            Object.assign(params, {search});
        }
        if (allergens) {
            Object.assign(params, {allergens});
        }
        return await this.dataService.getMultiple('/article', Article, params).toPromise();
    }

    public async getTopArticles(): Promise<Article[]> {
        return await this.dataService.getMultiple('/article/top', Article).toPromise();
    }

    public async getFeaturedArticles(): Promise<Article[]> {
        return await this.dataService.getMultiple('/article/featured', Article).toPromise();
    }

    /**
     * This method returns an article with the given id.
     * @param id the id of the article
     */
    public async getArticle(id: number): Promise<Article> {
        return await this.dataService.get<Article>('/article/' + id.toString(10))
            .pipe(
                map(response => {
                    return new Article().deserialize(response) as Article;
                })
            )
            .toPromise();
    }

    /**
     * This method returns a string with all available days of an article.
     * @param article the article
     */
    getAvailableDays(article: Article) {
        let availableDays = '';
        for (let i = 0; i < article.availableDays.length; i++) {
            if (i === article.availableDays.length - 1 && i > 0) {
                const prev = article.availableDays[i - 1];
                if (prev === (article.availableDays[i] - 1)) {
                    availableDays += '-' + Day[article.availableDays[i]];
                }
            } else if (i > 0) {
                const prev = article.availableDays[i - 1];
                if (prev !== (article.availableDays[i] - 1)) {
                    availableDays += '-' + Day[prev] + ', ' + Day[article.availableDays[i]];
                }
            } else {
                availableDays += Day[article.availableDays[i]];
            }
        }
        return availableDays;
    }

    public getAllergens(): Promise<Allergen[]> {
        return this.dataService.get('/allergen', environment.clientId)
            .pipe(
                map((response: any[]) => {
                    const allergens = [];

                    response.forEach(item => {
                        allergens.push(new AllergenDTO().deserialize(item) as Allergen);
                    });

                    return allergens;
                })
            ).toPromise();
    }
}
