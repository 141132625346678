import {Component, OnDestroy, OnInit} from '@angular/core';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {Subscription} from 'rxjs';
import {Platform} from '@ionic/angular';

@Component({
    selector: 'app-modal-no-connection',
    templateUrl: './modal-no-connection.component.html',
    styleUrls: ['./modal-no-connection.component.scss'],
})
export class ModalNoConnectionComponent implements OnInit, OnDestroy {
    private subscription: Subscription;

    constructor(
        private splashScreen: SplashScreen,
        private platform: Platform
    ) {
    }

    ngOnInit() {
        this.subscription = this.platform.backButton.subscribeWithPriority(101, async () => {

        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public restartApp() {
        this.splashScreen.show();
        window.location.reload();
    }
}
