import {Injectable} from '@angular/core';
import {Client, ClientDTO} from '../models/client.model';
import {DataService} from './data.service';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {ImageService} from './util/image.service';

@Injectable({
    providedIn: 'root'
})
export class ClientService {
    get client(): Client {
        return this._client;
    }

    set client(value: Client) {
        this._client = value;
    }

    private _client: Client = null;

    constructor(
        private dataService: DataService,
        private imageService: ImageService
    ) {
    }

    public async getClient(): Promise<Client> {
        if (this.client) {
            return this.client;
        } else {
            return this.dataService.get<ClientDTO>('/client/' + environment.clientId)
                .pipe(
                    map(async data => {
                        this.client = new Client().deserialize(data) as Client;
                        return this.client;
                    })
                ).toPromise();
        }
    }

    public async getPickupTimes(): Promise<[{ day: number, hours: number[], minutes: number[], summary: string[] }]> {
        return await this.dataService.get<[
            {
                day: number,
                hours: number[],
                minutes: number[],
                summary: string[]
            }
        ]>('/client/pickup/' + environment.clientId)
            .pipe(
                map(response => {
                    response.forEach((item, index) => {
                        response[index].day = item.day === 6 ? 0 : item.day + 1;
                    });
                    return response;
                })
            )
            .toPromise();
    }

    public async getAppStore(bundleId: string): Promise<{ trackId: string }> {
        return await this.dataService.get<{ trackId: string }>('/client/app-store', {bundleId}).toPromise();
    }
}
