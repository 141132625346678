import {Injectable, NgZone} from '@angular/core';
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from '@capacitor/push-notifications';
import {Router} from '@angular/router';
import {OrderService} from '../order.service';
import {DataService} from '../data.service';
import {Subject} from 'rxjs';
import {PushNotificationType} from '../../enums/push-notification-type.enum';
import {AlertController, Platform, ToastController} from '@ionic/angular';
import {PushNotification} from '../../models/push-notification';
import {DeviceService} from '../util/device.service';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {ImageService} from '../util/image.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    get pushNotifications(): PushNotification[] {
        return this._pushNotifications;
    }

    set pushNotifications(value: PushNotification[]) {
        this._pushNotifications = value;
    }

    get unseenNotifications(): PushNotification[] {
        return this._pushNotifications.filter(pushNotification => !pushNotification.seen);
    }

    get pushNotificationToken(): string {
        return this._pushNotificationToken;
    }

    set pushNotificationToken(value: string) {
        this._pushNotificationToken = value;
    }

    private _pushNotificationToken = '';

    public onTokenReceived = new Subject<string>();
    public onNotificationReceived = new Subject();

    private _pushNotifications: PushNotification[] = [];

    constructor(
        private router: Router,
        private orderService: OrderService,
        private dataService: DataService,
        private deviceService: DeviceService,
        private imageService: ImageService,
        private alertController: AlertController,
        private platform: Platform,
        private zone: NgZone,
        private toastController: ToastController,
        private appVersion: AppVersion
    ) {

    }

    public addPushNotificationEventListeners() {
        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token: Token) => {
                this.pushNotificationToken = token.value;
                this.onTokenReceived.next(this.pushNotificationToken);
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
            }
        );
        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            async (notification: PushNotificationSchema) => {
                this.onNotificationReceived.next();
                if (notification.data && notification.data.type) {
                    switch (notification.data.type) {
                        case PushNotificationType.DEFAULT:
                            const defaultToast = await this.toastController.create({
                                header: notification.title,
                                message: notification.body,
                                position: 'bottom',
                                color: 'dark',
                                duration: 5000,
                                buttons: [
                                    {
                                        side: 'end',
                                        icon: 'arrow-forward-circle-outline',
                                        handler: async () => {
                                            this.zone.run(async () => {
                                                await this.router.navigateByUrl('/initialization');
                                                await this.router.navigateByUrl('/notifications');
                                            });
                                        }
                                    }
                                ]
                            });
                            await defaultToast.present();
                            break;
                        case PushNotificationType.ARTICLE:
                            const articleToast = await this.toastController.create({
                                header: notification.title,
                                message: notification.body,
                                position: 'bottom',
                                color: 'dark',
                                duration: 5000,
                                buttons: [
                                    {
                                        side: 'end',
                                        icon: 'arrow-forward-circle-outline',
                                        handler: async () => {
                                            this.zone.run(async () => {
                                                await this.router.navigateByUrl('/initialization');
                                                await this.router.navigateByUrl('/notifications');
                                            });
                                        }
                                    }
                                ]
                            });
                            await articleToast.present();
                            break;
                        case PushNotificationType.CATEGORY:
                            const categoryToast = await this.toastController.create({
                                header: notification.title,
                                message: notification.body,
                                position: 'bottom',
                                color: 'dark',
                                duration: 5000,
                                buttons: [
                                    {
                                        side: 'end',
                                        icon: 'arrow-forward-circle-outline',
                                        handler: async () => {
                                            this.zone.run(async () => {
                                                await this.router.navigateByUrl('/initialization');
                                                await this.router.navigateByUrl('/notifications');
                                            });
                                        }
                                    }
                                ]
                            });
                            await categoryToast.present();
                            break;
                    }
                } else {
                    const alert = await this.alertController.create({
                        header: notification.title,
                        message: notification.body,
                        buttons: [
                            {
                                text: 'OK',
                                handler: async () => {
                                    this.zone.run(async () => {
                                        await this.router.navigateByUrl('/initialization');
                                        await this.router.navigateByUrl('/more/orders');
                                    });
                                }
                            }]
                    });
                    await alert.present();
                }
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            async (notificationAction: ActionPerformed) => {
                const notification = notificationAction.notification;

                if (notification.data && notification.data.type) {
                    this.zone.run(async () => {
                        await this.router.navigateByUrl('/initialization');
                        await this.router.navigateByUrl('/notifications');
                    });
                } else {
                    this.zone.run(async () => {
                        // this.orderService.updateOrder();
                        await this.router.navigateByUrl('/initialization');
                        await this.router.navigateByUrl('/more/orders');
                    });
                }
            }
        );
    }

    public async setupPushNotifications(): Promise<void> {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        const notificationPermission = await PushNotifications.requestPermissions();
        if (notificationPermission.receive === 'granted') {
            await PushNotifications.register();
        }
    }

    public async registerNotifications(notificationToken: string) {
        const uniqueDeviceId = this.deviceService.deviceId;
        const appVersion = await this.appVersion.getVersionNumber();
        if (!uniqueDeviceId || !appVersion) {
            return;
        }
        return await this.dataService.post('/notification/register', {}, {
            notificationToken,
            uniqueDeviceId,
            appVersion
        }).toPromise();
    }

    public async getNotifications(token: string, limit = 0): Promise<PushNotification[]> {
        const params = {token};
        if (limit) {
            Object.assign(params, {limit});
        }
        this.pushNotifications = await this.dataService.get<PushNotification[]>('/notification', params).toPromise();

        return this.pushNotifications;
    }

    public async markAsSeen(pushNotification: PushNotification) {
        const updatedPushNotification = await this.dataService.put<PushNotification>(
            '/notification/' + pushNotification.id + '/mark-as-seen'
        ).toPromise();
        const oldPushNotificationIndex = this.pushNotifications.findIndex(notification => notification.id === updatedPushNotification.id);
        if (oldPushNotificationIndex > -1) {
            this.pushNotifications[oldPushNotificationIndex] = updatedPushNotification;
        }
    }

    public async handlePushNotification(pushNotification: PushNotification) {
        switch (pushNotification.linkToType) {
            case PushNotificationType.DEFAULT:
                const defaultAlert = await this.alertController.create({
                    header: pushNotification.title,
                    message: pushNotification.description,
                    buttons: [
                        {
                            text: 'Alles klar',
                            role: 'cancel'
                        }
                    ]
                });
                await defaultAlert.present();
                break;
            case PushNotificationType.ARTICLE:
                const articleAlert = await this.alertController.create({
                    header: pushNotification.title,
                    message: pushNotification.description,
                    buttons: [
                        {
                            text: 'Alles klar',
                            role: 'cancel'
                        },
                        {
                            text: 'Anzeigen',
                            handler: async () => {
                                if (pushNotification.linkToId) {
                                    await this.router.navigateByUrl('/products/' + pushNotification.linkToId);
                                }
                                await articleAlert.dismiss();
                            }
                        }
                    ]
                });
                await articleAlert.present();
                break;
            case PushNotificationType.CATEGORY:
                const categoryAlert = await this.alertController.create({
                    header: pushNotification.title,
                    message: pushNotification.description,
                    buttons: [
                        {
                            text: 'Alles klar',
                            role: 'cancel'
                        },
                        {
                            text: 'Anzeigen',
                            handler: async () => {
                                if (pushNotification.linkToId) {
                                    await this.router.navigateByUrl('/products?category=' + pushNotification.linkToId);
                                }
                                await categoryAlert.dismiss();
                            }
                        }
                    ]
                });
                await categoryAlert.present();
                break;
        }
        await this.markAsSeen(pushNotification);
    }
}
