import {Component, Input, OnInit} from '@angular/core';
import {Branch} from '../../models/branch.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {BranchService} from '../../services/branch.service';

@Component({
    selector: 'app-modal-select-branch',
    templateUrl: './modal-select-branch.component.html',
    styleUrls: ['./modal-select-branch.component.scss'],
})
export class ModalSelectBranchComponent implements OnInit {
    @Input() branches: Branch[] = [];

    public branchForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private modalController: ModalController,
        private branchService: BranchService
    ) {
    }

    ngOnInit() {
        this.branchForm = this.formBuilder.group({
            branch: new FormControl(null, Validators.required)
        });

        if (this.branchService.selectedBranch) {
            this.branchForm.patchValue({branch: this.branches.find(branch => branch.id === this.branchService.selectedBranch.id) ?? null});
        }
    }

    async saveSelectedBranch() {
        if (this.branchForm.invalid) {
            return;
        }
        this.branchService.selectedBranch = this.branchForm.controls.branch.value;
        await this.modalController.dismiss();
    }
}
