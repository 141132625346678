import {Serializable} from './serializable.abstract';
import {Category} from './category.model';
import {UnitDefinition} from './unit-definition';
import {SellUnitDefinition} from './sell-unit-definition';
import {Image} from './image.model';

export class BaseArticleDTO extends Serializable {
    title: string;
    description: string;
    images: Image[] = [];
    price: number;
    unit: UnitDefinition;
    sellUnit: SellUnitDefinition;
    maxOrderAmount: number;
    minOrderAmount: number;
    taxRate: string;
    topArticle: string;
    hoursLeadTime: string;
    specialPrice: number;
    specialPriceValidFrom: Date;
    specialPriceValidUntil: Date;
    offerValidFrom: Date;
    offerValidUntil: Date;
    clickAmount: number;
    categories: Category[] = [];
    availableDays: [];
    thumbnail: Image;

    deserialize(input: any): Serializable {
        super.deserialize(input);
        if (input.category && typeof input.category.length !== 'undefined') {
            this.deserializeComplexArray('category', input, Category);
        }
        this.deserializeComplexType('unit', input, UnitDefinition);
        this.deserializeComplexType('sellUnit', input, SellUnitDefinition);
        return this;
    }
}

export class BaseArticle extends BaseArticleDTO {
    coverImage: Image;
    icon = '';
}
