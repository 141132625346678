import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './components/app/app.component';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RequestInterceptor} from './interceptors/request.interceptor';

import {UniqueDeviceID} from '@ionic-native/unique-device-id/ngx';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Device} from '@ionic-native/device/ngx';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {ModalUpdateComponent} from './modals/modal-update/modal-update.component';
import {Market} from '@ionic-native/market/ngx';
import {ModalAvailabilityComponent} from './modals/modal-availability/modal-availability.component';
import {Vibration} from '@ionic-native/vibration/ngx';
import {ModalCommentCRUDComponent} from './modals/modal-comment-crud/modal-comment-crud.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {StripeService} from './services/util/stripe.service';
import {ModalMenuConfigurationComponent} from './modals/modal-menu-configuration/modal-menu-configuration.component';
import {MatStepperModule} from '@angular/material/stepper';
import {ComponentsModule} from './components/components.module';
import {DashboardPage} from './pages/dashboard/dashboard.page';
import {ModalNoConnectionComponent} from './modals/modal-no-connection/modal-no-connection.component';
import {CategorySelectComponent} from './modals/category-select/category-select.component';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {ModalLunchMenuConfigurationComponent} from './modals/modal-lunch-menu-configuration/modal-lunch-menu-configuration.component';
import {OrderDetailPage} from './modals/order-detail/order-detail.page';
import {MenusPageModule} from './pages/menus/menus.module';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {environment} from '../environments/environment';
import {ModalSelectBranchComponent} from './modals/modal-select-branch/modal-select-branch.component';
import {SwiperModule} from 'swiper/angular';
import { DatePipe } from '@angular/common';

registerLocaleData(localeDe, 'de');

const config = {};

if (environment.desktop) {
    Object.assign(config, {mode: 'ios'});
}

@NgModule({
    declarations: [AppComponent, ModalUpdateComponent, ModalAvailabilityComponent, ModalCommentCRUDComponent, ModalMenuConfigurationComponent, ModalNoConnectionComponent, ModalSelectBranchComponent, DashboardPage, CategorySelectComponent, ModalLunchMenuConfigurationComponent, OrderDetailPage],
    entryComponents: [
        ModalUpdateComponent,
        ModalAvailabilityComponent,
        ModalCommentCRUDComponent,
        ModalMenuConfigurationComponent,
        ModalNoConnectionComponent,
        ModalSelectBranchComponent,
        CategorySelectComponent,
        ModalLunchMenuConfigurationComponent,
        OrderDetailPage
    ],
    imports: [BrowserModule, IonicModule.forRoot(config), AppRoutingModule, HttpClientModule, BrowserAnimationsModule, FormsModule, MatStepperModule, ComponentsModule, MenusPageModule, ReactiveFormsModule, SwiperModule],
    providers: [
        Device,
        StatusBar,
        SplashScreen,
        InAppBrowser,
        DatePipe,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {
            provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true
        },
        UniqueDeviceID,
        AppVersion,
        Market,
        Vibration,
        Keyboard,
        StripeService,
        {
            provide: LOCALE_ID,
            useValue: 'de'
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
