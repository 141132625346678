import {Serializable} from './serializable.abstract';
import {Image} from './image.model';
import {BaseBasketItemDTO} from './base-basket-item.model';
import {UnitDefinition} from './unit-definition';
import {SellUnitDefinition} from './sell-unit-definition';

export class CheckoutBasketItemDTO extends BaseBasketItemDTO {
    images: Image[] = [];
    price = 0;
    status = '';
    title = '';
    unit: UnitDefinition;
    sellUnit: SellUnitDefinition;
    comment = '';
    clickAmount = 0;
    maxOrderAmount = 0;
    weekDay: number;
    date: string;
    minItems: number;

    deserialize(input: any): Serializable {
        super.deserialize(input);
        this.deserializeComplexType('unit', input, UnitDefinition);
        this.deserializeComplexType('sellUnit', input, SellUnitDefinition);
        this.deserializeComplexArray('images', input, Image);
        return this;
    }
}

export class CheckoutBasketItem extends CheckoutBasketItemDTO {
    public get isDecreasable(): boolean {
        if (this.minItems) {
            return (this.amount - this.clickAmount) >= this.minItems;
        }
        return this.amount - this.clickAmount !== 0;
    }

    public get isIncreasable(): boolean {
        return this.amount < this.maxOrderAmount;
    }
}


