import {Injectable} from '@angular/core';
import {Order} from '../models/order.model';
import {DataService} from './data.service';
import {Basket} from '../models/basket.model';
import {map} from 'rxjs/operators';
import {Device} from '@ionic-native/device/ngx';
import {CheckoutResponse} from '../models/checkout-response.model';
import {DeviceService} from './util/device.service';
import {OrderResponse} from '../models/order-response.model';
import {BranchService} from './branch.service';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    public activeOrder: Order;

    constructor(
        private dataService: DataService,
        private device: Device,
        private deviceService: DeviceService,
        private branchService: BranchService
    ) {
    }

    public async getOrders(orderProperty = 'tstamp', orderDirection = 'ASC'): Promise<OrderResponse[]> {
        const deviceUuid = this.deviceService.deviceId;
        if (!deviceUuid) {
            return [];
        }
        const params = {
            orderProperty,
            orderDirection
        };
        return await this.dataService.getMultiple('/order/by-device/' + deviceUuid, OrderResponse, params).toPromise();
    }

    public getOrder(id: number): Promise<OrderResponse> {
        const deviceUuid = this.deviceService.deviceId;
        if (!deviceUuid) {
            return null;
        }
        return this.dataService.get<OrderResponse>('/order/by-device/' + deviceUuid + '/' + id)
            .pipe(
                map(response => {
                    return new OrderResponse().deserialize(response) as OrderResponse;
                })
            )
            .toPromise();

    }

    public async checkout(basket: Basket): Promise<CheckoutResponse> {
        const params = {};
        if (this.branchService.selectedBranch) {
            Object.assign(params, {branch: this.branchService.selectedBranch.id});
        }
        return await this.dataService.post<CheckoutResponse>(
            '/order/checkout',
            params,
            basket.serializeCheckout()
        )
            .pipe(
                map(response => {
                    response = new CheckoutResponse().deserialize(response) as CheckoutResponse;

                    response.availableDays.forEach((availableDay, index) => {
                        response.availableDays[index] = availableDay === 6 ? 0 : availableDay + 1;
                    });

                    response.disabledDays.forEach((disabledDay, index) => {
                        response.disabledDays[index] = disabledDay === 6 ? 0 : disabledDay + 1;
                    });

                    return response;
                })
            )
            .toPromise();
    }

    public postOrder(order: Order) {
        const params = {};
        if (this.branchService.selectedBranch) {
            Object.assign(params, {branch: this.branchService.selectedBranch.id});
        }
        return this.dataService.post<Order>('/order', params, order.serialize())
            .pipe(
                map(data => {
                    return new Order().deserialize(data) as Order;
                })
            ).toPromise();
    }

    public prepareActiveOrder(
        checkout: CheckoutResponse
    ) {
        this.activeOrder = new Order();
        this.activeOrder.pickupMin = checkout.pickupMin;
        this.activeOrder.pickupMax = checkout.pickupMax;
        this.activeOrder.availableDays = checkout.availableDays;
        this.activeOrder.disabledDays = checkout.disabledDays;
        this.activeOrder.leadTimeMax = checkout.leadTimeMax;
        this.activeOrder.paymentPossible = checkout.paymentPossible;
    }
}
