<ion-header>
    <ion-toolbar color="primary">
        <ion-title>Menus</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content [class.desktop]="environment.desktop">
    <ion-grid style="height: 100%">
        <ion-row style="height: 100%">
            <ion-col size="12" size-lg="8" offset-lg="2">
                <ion-row class="ion-margin-vertical" *ngIf="lunchWeeks.length">
                    <ion-col size="12" class="ion-text-center">
                        Mittagsmenüs, KW {{lunchWeekRange}}<br><strong>Aktuelle
                        KW {{dateService.getNumberOfWeek()}}</strong>
                    </ion-col>

                    <ion-col *ngIf="clientService.client.additionalInformationLunchMenu" class="ion-text-center">
                        {{ clientService.client.additionalInformationLunchMenu }}
                    </ion-col>
                </ion-row>
                <div *ngIf="!lunchWeeks.length && !loading"
                     style="display: flex; justify-content: center; flex-direction: column; height: 100%">
                    <ion-row>
                        <ion-col>
                            <div class="ion-padding-vertical styleCenter">
                                <img style="width: 60%" src="/assets/images/no_menues_found.svg" alt="No Menus found">
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col class="ion-padding-vertical ion-text-center">
                            <span class="not-available-text-bold"><b>Leider sind diese Woche<br>keine Mittagsmenüs verfügbar</b></span>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col class="ion-padding-vertical ion-text-center">
                            <span class="not-available-text">Schau doch nächste Woche<br>nochmal vorbei</span>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="12" class="ion-padding-top">
                            <ion-button routerLink="/products" color="primary" expand="full">Zu unseren Produkten
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </div>
                <div class="lunchDaySwiper" [class.ion-hide]="!lunchWeeks.length">
                    <swiper (swiper)="setSwiperInstance($event)" id="slides"
                            [slidesPerView]="environment.desktop ? slidesPerViewDesktop : 3.5" [spaceBetween]="0"
                            [slidesOffsetBefore]="0" [slidesOffsetAfter]="0" [navigation]="true">
                        <ng-container *ngFor="let day of lunchDays; index as index">
                            <ng-template swiperSlide>
                                <div class="lunchDay" [class.not-expired]="!day.expired"
                                     [class.expired]="day.expired" [class.active]="activeSlideIndex === index"
                                     (click)="openMenu(index)">
                                    <div class="article-content">
                                        <div>
                                            <span class="article-content-text">{{ day.date | date: 'EEEE' }}</span>
                                            <br>
                                            <span class="article-content-text-bold">{{day.date | date: 'dd'}}
                                                .</span><br>
                                            <span class="article-content-text">{{day.date | date: 'MMMM'}}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </swiper>
                </div>
                <div *ngIf="currentWeek">
                    <ion-row class="ion-margin-top">
                        <ng-container *ngFor="let lunchDay of lunchDays; index as index">
                            <ng-container *ngFor="let lunchArticle of lunchDay.lunchArticles">
                                <ion-col *ngIf="index === activeSlideIndex" size="12" size-lg="6">
                                    <app-menu-card-item
                                            (click)="openMenuModal(lunchArticle, lunchDay)"
                                            [lunchArticle]="lunchArticle"
                                            [class.article-expired]="lunchDay.expired && lunchDay.weekDay !== currentDayOfWeek"></app-menu-card-item>
                                </ion-col>
                            </ng-container>
                            <ng-container *ngIf="!lunchDay.lunchArticles">
                                <ion-col *ngIf="index === activeSlideIndex" offset-lg="3" size="12" size-lg="6">
                                    <ion-row>
                                        <ion-col>
                                            <div class="ion-padding-vertical styleCenter">
                                                <img style="width: 60%" src="/assets/images/no_menues_found.svg"
                                                     alt="No Menus found">
                                            </div>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col class="ion-padding-vertical ion-text-center">
                                            <span class="not-available-text-bold"><b>Leider sind heute<br>keine Mittagsmenüs verfügbar</b></span>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col class="ion-padding-vertical ion-text-center">
                                            <span class="not-available-text">Schau doch morgen<br>nochmal vorbei</span>
                                        </ion-col>
                                    </ion-row>
                                </ion-col>
                            </ng-container>
                        </ng-container>
                    </ion-row>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
