import {BaseArticle} from './base-article.model';
import {Serializable} from './serializable.abstract';
import {SellUnitDefinition} from './sell-unit-definition';
import {UnitDefinition} from 'src/app/models/unit-definition';
import {OrderItemType} from 'src/app/enums/order-item-type.enum';
import {AdditionalArticle} from 'src/app/models/additional-article';
import {Category} from 'src/app/models/category.model';

export class OrderArticleDTO extends Serializable {
    title: string;
    count: number;
    pricePerArticle: number;
    comment: string;
    sellUnit: SellUnitDefinition;
    additionalArticles: AdditionalArticle[] = [];
    unit: UnitDefinition;
    baseArticle: { id: number } = null;
    type: OrderItemType;
    tstamp: Date;
    date: string;
    categories: Category[] = [];
    internalCategories: Category[] = [];
    net: number;
    gross: number;
    tax: number;

    deserialize(input: any): Serializable {
        super.deserialize(input);
        this.deserializeComplexArray('additionalArticles', input, AdditionalArticle);
        this.deserializeComplexArray('categories', input, Category);
        this.deserializeComplexArray('internalCategories', input, Category);
        this.deserializeComplexType('sellUnit', input, SellUnitDefinition);
        this.deserializeComplexType('unit', input, UnitDefinition);
        this.deserializeDate('tstamp', input);

        return this;
    }
}

export class OrderArticle extends OrderArticleDTO {
    article: BaseArticle;
}
