import {Serializable} from './serializable.abstract';
import {OrderItemType} from '../enums/order-item-type.enum';
import {MenuArticle} from './menu-article.model';

export class BaseBasketItemDTO extends Serializable {
    amount = 0;
    type: OrderItemType;
    additionalArticles: MenuArticle[] = [];
    title = '';
    unit;
    sellUnit;

    get hash(): string {
        let hash: string = this.id.toString(10);
        hash += '_' + this.type;
        this.additionalArticles.sort((a, b) => (a.menuCategory - b.menuCategory) ? 1 : ((b.menuCategory > a.menuCategory) ? -1 : 0));
        this.additionalArticles.forEach((menuArticle: MenuArticle) => {
            hash += '_' + menuArticle.menuCategory.toString(10) + '-' + menuArticle.id.toString(10);
        });
        return hash;
    }

    deserialize(input: any): Serializable {
        super.deserialize(input);

        this.deserializeComplexArray('additionalArticles', input, MenuArticle);

        return this;
    }
}
