import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Market} from '@ionic-native/market/ngx';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {ModalController, Platform} from '@ionic/angular';
import {ClientService} from '../../services/client.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-modal-update',
    templateUrl: './modal-update.component.html',
    styleUrls: ['./modal-update.component.scss'],
})
export class ModalUpdateComponent implements OnInit, OnDestroy {
    @Input() breaking = true;

    public appStoreLoading = false;

    private subscription: Subscription;

    constructor(
        private modalController: ModalController,
        private market: Market,
        private appVersion: AppVersion,
        private platform: Platform,
        private clientService: ClientService
    ) {
    }

    ngOnInit() {
        this.subscription = this.platform.backButton.subscribeWithPriority(101, async () => {

        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public async dismiss() {
        await this.modalController.dismiss();
    }

    public async openMarket() {
        this.appStoreLoading = true;
        const packageName = await this.appVersion.getPackageName();
        if (packageName) {
            if (this.platform.is('android')) {
                await this.market.open(packageName);
                this.appStoreLoading = false;
            } else if (this.platform.is('ios')) {
                const appStoreResponse = await this.clientService.getAppStore(packageName);
                if (appStoreResponse && appStoreResponse.trackId) {
                    await this.market.open('id' + appStoreResponse.trackId);
                    this.appStoreLoading = false;
                    return;
                }
                window.location.href = 'itms-apps://itunes.apple.com/';
                this.appStoreLoading = false;
            }
        }
    }
}
